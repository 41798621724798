import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../functions/functions';
import { useState, useEffect } from "react";

function Footer() {
    const [content, setContent] = useState(null);

    useEffect(() => {
        fetch("/data/content.json")
            .then((res) => res.json())
            .then((data) => setContent(data))
            .catch((error) => console.error("Error loading JSON:", error));
    }, []);

    // Jeśli content nie jest jeszcze załadowany, zwracamy pusty footer lub spinner
    if (!content) {
        return <footer>Loading...</footer>;
    }

    const { footer } = content;

    return (
        <footer>
            <Container className='footer-container'>
                <Row className='footer-row'>
                    <Col className='d-none d-md-block'>
                        <a href='/#functionality' className='menu-item hidden-in-menu'>
                            {footer.functions}
                        </a>
                    </Col>

                    <Col className='text-start text-md-center'>
                        <a href="/#functionality" className='menu-item hidden-in-menu'>
                            {footer.howItWorks}
                        </a>
                    </Col>

                    <Col className='d-none d-md-block'>
                        {footer.aboutUs}
                    </Col>

                    <Col className='text-end text-md-center'>
                        {/* <Link to="/privacy" className='menu-item hidden-in-menu' onClick={scrollToTop}>
                            {footer.privacyPolicy}
                        </Link> */}
                    </Col>

                    <Col className='d-none d-md-block'>
                        {footer.contact}
                    </Col>
                </Row>

                <Row className='footer-row'>
                    <Col className='d-none d-md-block'>
                        <a href="#offer" className='menu-item hidden-in-menu'>
                            {footer.pricing}
                        </a>
                    </Col>

                    <Col></Col>
                    <Col></Col>
                    <Col></Col>

                    <Col className='text-end text-md-center'>
                        <a href='mailto:kontakt@egodziny.pl'>
                            {footer.email}
                        </a>
                        <br />
                        <a href='tel:+48515800125'>
                            {footer.phone}
                        </a>
                    </Col>
                </Row>

                <Row className='footer-row text-center p-3 p-md-1'>
                    <Col>
                        <h5>{footer.copyright}</h5>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
