import { Container, Image } from 'react-bootstrap';
import Slider from 'react-infinite-logo-slider'
import abovo from '../images/abovo.png';
import petrostal from '../images/petrostal.png';
import verisnet from '../images/verisnet.png';
import excento from '../images/excento.png';
import innova from '../images/innova.png';
import pg from '../images/pg.png';
import sanicom from '../images/sanicom.png';
import { useState, useEffect } from 'react';


function Partners() {

    const [content, setContent] = useState(null);

    useEffect(() => {
        fetch("/data/content.json")
            .then((res) => res.json())
            .then((data) => setContent(data))
            .catch((error) => console.error("Error loading JSON:", error));
    }, []);


    if (!content) return <div>Loading...</div>;

    const { partners } = content;

    return (
        <>
            <h3 className='px-5 text-center text-md-start'>
                {partners.title}
            </h3>

            <Container fluid className='partners'>
                <Slider
                    width="400px"
                    duration={40}
                    pauseOnHover={true}
                    blurBorders={true}
                >
                    <Slider.Slide>
                        <Image src={abovo} alt="abovo" className='w-36' />
                    </Slider.Slide>

                    <Slider.Slide>
                        <Image src={petrostal} alt='petrostal' className='w-36' />
                    </Slider.Slide>

                    <Slider.Slide>
                        <Image src={verisnet} alt='verisnet' className='w-36' />
                    </Slider.Slide>

                    <Slider.Slide>
                        <Image src={excento} alt='excento' className='w-36' />
                    </Slider.Slide>

                    <Slider.Slide>
                        <Image src={innova} alt='innova' className='w-36' />
                    </Slider.Slide>

                    <Slider.Slide>
                        <Image src={pg} alt='pg' className='w-36' />
                    </Slider.Slide>

                    <Slider.Slide>
                        <Image src={sanicom} alt='sanicom' className='w-36' />
                    </Slider.Slide>
                </Slider>
            </Container>
        </>
    );
}

export default Partners;